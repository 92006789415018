<template>
  <div class="payouts">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path"></div>
        <financial-info />
        <bank-tab
          :brandUrl="subdomain"
          :info="bankInfo"
          @updateShowContinueBar="updateShowContinueBar"
        />
        <section>
          <div class="row">
            <div class="col full">
              <DataTable
                title="Lifetime Event Payouts"
                :headers="headers"
                endpoint="/company/payouts"
                :params="{ subdomain: this.subdomain }"
                ref="table"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-if="isSetup" class="continue-bar">
      <div class="row">
        <div class="col full flex">
          <p>
            Now that you've completed the brand building steps click continue.
          </p>
          <diyobo-button
            txt="Continue"
            class="primary"
            :disabled="brandPayout < 1"
            @click="goToBrands"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BankTab from "../../components/bankTab.vue";
import financialInfo from "../../components/company/FinancialInfo";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import dateFormat from "dateformat";
import TableTote from "@/components/TableTote.vue";
import DataTable from "@/components/DataTable.vue";

export default {
  components: {
    TableTote,
    financialInfo,
    BankTab,
    Breadcrumbs,
    DiyoboButton,
    DataTable
  },
  data() {
    return {
      subdomain: "",
      bankInfo: null,
      payoutsTableData: {},
      showContinueBar: false,
      headers: [
        { text: "Event", value: "eventName" },
        { text: "Payout", value: "amount", format: "currency" },
        { text: "Details", value: "details" },
        { text: "Payout Date", value: "date", format: "date" }
      ],
      rates: null
    };
  },
  created() {
    this.$store.commit("setTitle", "Payouts");
    this.getUrl();
    this.getInfo();
  },
  computed: {
    userEmail() {
      return this.$store.state.user.email;
    },
    brandPayout() {
      return this.$store.state.user.plannerPayouts;
    },
    isSetup() {
      if (this.$route.query.setup) {
        return true;
      }
    }
  },
  methods: {
    updateShowContinueBar(s) {
      this.showContinueBar = false;
    },
    goToBrands() {
      if (this.brandPayout > 0) {
        this.$router.push("/brands");
      }
    },
    getUrl() {
      let subdomain = this.$route.params.subdomain;
      this.subdomain = subdomain;
    },
    getInfo() {
      let objToSend = {
        userEmail: this.userEmail,
        type: "bankInfo",
        url: this.subdomain
      };

      this.$axios.post("/company/get-info", objToSend).then(({ data }) => {
        if (data) {
          this.bankInfo = data;
        } else {
          this.showContinueBar = true;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.continue-bar {
  display: flex;
  position: fixed;
  width: 100vw;
  max-width: 1100px;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid var(--dashboard-border);
  border-radius: 8px;
  background-image: var(--dashboard-tiles);
  box-shadow: var(--dashboard-shadow);
  z-index: 100;

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    .col {
      p {
        font-size: 16px;
      }

      &.flex {
        display: flex;
        justify-content: space-between;
      }
      &.center {
        justify-content: center;
      }
      &.full {
        width: 100%;
      }
      // end full col

      &.half {
        width: calc(50% - 10px);
      }
      // end half col

      &.quarter {
        width: calc(25% - 10px);
      }
      // end 1/4 col

      &.third {
        width: calc(33.33% - 10px);
      }

      &.quarterx3 {
        width: calc(75% - 10px);
      }
      // end 3/4 col

      &.eighth {
        width: calc(12.5% - 10px);
      }
    }
    // end col
  }
  @media screen and (max-width: 1140px) {
    margin: 0 20px;
    width: calc(100% - 60px);
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;

    .right {
      margin-top: 20px;
    }
  }
}
</style>
